import bulmaCarousel from 'bulma-carousel';

document.addEventListener('turbolinks:load', () => {
  let element = document.querySelector('#carousel');

  if (element) {
    bulmaCarousel.attach('#carousel', {
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        duration: 5000,
        autoplaySpeed: 4000,
    });
  }
})

document.addEventListener('turbolinks:load', () => {
  window.fbAsyncInit = function() {
    FB.init({
      xfbml            : true,
      version          : 'v10.0'
    });
  };

  (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  }
  (document, 'script', 'facebook-jssdk'));
})




