import $ from 'jquery'

//burger menu
document.addEventListener('turbolinks:load', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
  
      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
  
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);
  
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
  
        });
      });
    }
  
});

//notification delete
document.addEventListener('turbolinks:load', () => {
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    const $notification = $delete.parentNode;

    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification);
      window.location.reload(0);
    });
  });
});

//back to top
document.addEventListener('turbolinks:load', () => {
  window.jQuery = $
  window.$ = $
  $(function(){
    $("#gotop").click(function(){
      jQuery("html,body").animate({
          scrollTop:0
      },1000);
    });
    $(window).scroll(function() {
      if ( $(this).scrollTop() > 300){
          $('#gotop').fadeIn("fast");
      } else {
          $('#gotop').stop().fadeOut("fast");
      }
    });
  });
});


