import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "lenght", "width", "height", "total", "warmup" ]

  calculat(evt) {
    evt.preventDefault();

    let lenght = Number(this.lenghtTarget.value);
    let width = Number(this.widthTarget.value);
    let height = Number(this.heightTarget.value);
    let result = (lenght*width*height) / 1000

    this.totalTarget.value = result;

    if (result > 10 & result < 20 ) {
      this.warmupTarget.value = 25;
    } else if (result > 21 & result < 40) {
      this.warmupTarget.value = 50;
    } else if (result > 41 & result < 80) {
      this.warmupTarget.value = 100;
    } else if (result > 81 & result < 160) {
      this.warmupTarget.value = 200;
    } else if (result > 161 & result < 240) {
      this.warmupTarget.value = 300;
    } else {
      this.warmupTarget.value = "請洽本店諮詢";
    }
    
        
  }

}